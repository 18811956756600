import * as React from 'react';
import styled, { injectGlobal } from 'react-emotion';

import Header from './Header';

// tslint:disable-next-line:no-unused-expression
injectGlobal`
  @import url('https://fonts.googleapis.com/css?family=Inconsolata|Poppins:400,600,700&display=swap');

  html, body {
    width: 100%;
    margin: 0;
    min-height: 100%;
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

`;

const Container = styled.div``;

const Layout = ({
  children,
  className,
  logo
}: {
  logo?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}) => (
  <Container className={className}>
    <Header logo={logo} />
    {children}
  </Container>
);

export default Layout;
